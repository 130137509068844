<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="180px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="airForm.name"
            placeholder="请输入姓名"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="来访单位" prop="visitingUnit">
          <el-input
            v-model="airForm.visitingUnit"
            placeholder="请输入来访单位"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="健康状况" prop="healthState">
          <el-select
            v-model="airForm.healthState"
            placeholder="请选择健康状况"
            clearable
            filterable
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-option key="1" label="健康" :value="1" />
            <el-option key="2" label="良好" :value="2" />
            <el-option key="3" label="一般" :value="3" />
            <el-option key="4" label="较差" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="进出日期" prop="entryExitDate">
          <el-date-picker
            v-model="airForm.entryExitDate"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="进出事由" prop="reason">
          <el-input
            v-model="airForm.reason"
            placeholder="请输入进出事由"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="陪同人员" prop="escort">
          <el-input
            v-model="airForm.escort"
            placeholder="请输入陪同人员"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="airForm.remarks"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="外来人口姓名">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入外来人口姓名"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="creationTime" label="日期" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="visitingUnit" label="来访单位" />
      <el-table-column prop="reason" label="进出事由" />
      <el-table-column prop="healthState" label="健康状态">
        <template slot-scope="scope">
          <span v-if="scope.row.healthState == 1">健康</span>
          <span v-if="scope.row.healthState == 2">良好</span>
          <span v-if="scope.row.healthState == 3">一般</span>
          <span v-if="scope.row.healthState == 4">较差</span>
        </template>
      </el-table-column>
      <el-table-column prop="escort" label="陪同人员" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getOutsiderList,
  deleteOutsiderList,
  addOutsiderDetail,
  getOutsiderInfoDetail,
  updateOutsiderDetail,
} from "@/api/produce/produce.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceType: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        disinfectionObject: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["change"],
          },
        ],
      },
      productFormRules2: {
        name: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: ["change",'blur'],
          },
        ],
        visitingUnit: [
          {
            required: true,
            message: "来访单位不能为空",
            trigger: ["change",'blur'],
          },
        ],
        healthState: [
          {
            required: true,
            message: "健康状况不能为空",
            trigger: ["change",'blur'],
          },
        ],
        entryExitDate: [
          {
            required: true,
            message: "进出日期不能为空",
            trigger: ["change",'blur'],
          },
        ],
        reason: [
          {
            required: true,
            message: "进出事由不能为空",
            trigger: ["change",'blur'],
          },
        ],
        escort: [
          {
            required: true,
            message: "陪同人员不能为空",
            trigger: ["change",'blur'],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getOutsiderList(params);
    this.waterList = waterList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addOutsiderDetail(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateOutsiderDetail(params);
          }
          this.waterList = await getOutsiderList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addOutsiderDetail(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增外来人口登记单";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteOutsiderList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getOutsiderList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "外来人员登记列表";
      this.handleType = "update";
      getOutsiderInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        // this.runningTime = [res.startTime || "", res.endTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "外来人员登记列表";
      this.handleType = "detail";
      getOutsiderInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        // this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getOutsiderList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getOutsiderList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getOutsiderList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      console.log(val, "val");
      this.airForm.entryExitDate = val.join("-");
    },
    airDialogVisible(val){
      if(!val){
        this.airForm = {}
        this.runningTime = []
      }
    }
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
